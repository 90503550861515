h3 {
  font-weight: 500;
}

.logo {
  width: 100px;
  margin-right: 12px;
}

.stepper-nav {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.MuiButton-root.btn-light {
  color: #999;
}

.inline-icon {
  vertical-align: middle;
  padding-right: 3px;
}

.input-container {
  max-width: 420px;
  display: flex;
}

.info-container {
  max-width: 420px;
  margin-bottom: 30px;
  text-align: left;
  .email-label {
    text-transform: none;
    padding-left: 12px;
    max-width: 165px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .cancel {
    margin-right: 12px;
  }
  .verify {
    max-width: 300px;
  }
}

i.flag {
  display: inline-block;
  width: 21px;
  height: 16px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
i.flag-fr {
  background-image: url('/static/fr.svg');
}
i.flag-en {
  background-image: url('/static/en.svg');
}
.locale i.flag {
  margin-right: 6px;
  vertical-align: -2px;
}

.inline-adornment {
  padding-right: 6px;
}

p.error {
  color: #ce802b;
}

p.warning {
  font-weight: 500; 
  color: green;
}

.blank-button {
  width: 0;
  height: 48px;
  padding: 0;
  margin: 4px 0 0 52px;
}
