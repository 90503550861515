#app-container {
  .MuiToolbar-regular {
    min-height: 64px;
  }

  .MuiFormHelperText-root {
    color: #ce802b;
  }

  .MuiIconButton-root.button-edit {
    width: 48px;
    height: 48px;
    margin: 4px 0 0 4px;
  }
  .MuiFormControl-root.input-small {
    width: 180px;
    margin-right: 10px;
  }
  .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important;
    color: rgba(255, 255, 255, 0.9);
  }

  .instructions ul li.highlight {
    color: #ce802b;
  }
}
