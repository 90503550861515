html, body, #app-container, #page-container {
  height: 100%;
}

#app-container {
  padding-top: $navHeight;
}

#app-container > header {
  height: $navHeight;
  background: #f9f9f9;
  color: rgba(0,0,0,.9);
}

#page-container {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0;
  text-align: justify;

  main {
    flex: 1 0 auto;
  }

  footer {
    padding: 30px 0 10px;
    .container {
      display: flex;
      justify-content: center;
      a {
        text-decoration: none;
        padding: 2px 4px;
        margin: 6px 2px 0;
        font-weight: 500;
        color: #9c003c;
      }
    }
  }
}
